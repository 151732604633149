import { motion } from "framer-motion";
import React, { useState, useEffect } from "react";
import { images } from "../../constants";
import { client } from "../../client";

import "./CompanyActivity.scss";

const CompanyActivity = () => {
  const [activities, setActivities] = useState([]);

  useEffect(() => {
    const query = '*[_type == "activity"]';

    client.fetch(query).then((data) => setActivities(data));
  }, []);

  return (
    <div className="app__activity" id="activity">
      <motion.div
        whileInView={{ y: [0, 0], opacity: [0, 1] }}
        transition={{ duration: 0.6 }}
        className="app__activity-flex-left"
      >
        <p className="app__activity-flex-left-title">Tevékenységi kör</p>
        <ul className="app__activity-flex-left-content">
          {activities.map((activity, idx) => (
            <li key={idx}>{activity.description}</li>
          ))}
        </ul>
      </motion.div>
      <motion.div
        whileInView={{ y: [0, 0], opacity: [0, 1] }}
        transition={{ duration: 0.6 }}
        className="app__activity-flex-right"
      >
        <div className="app__activity-flex-right-images">
          <img src={images.hgh_arch} className="img" alt="HGH medence" />
        </div>
      </motion.div>
    </div>
  );
};

export default CompanyActivity;
